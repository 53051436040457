@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

@tailwind base;
@tailwind components;
body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@tailwind utilities;

h1 {
  font-weight: 900 !important;
}

pre {
  white-space: break-spaces;
}

@import url("https://fonts.googleapis.com/css?family=Open+Sans");

/* START: EmailEntry.jsx styles  */
.emailInput {
  width: 100%;
  height: 45px;
  padding: 0 1rem;
  margin-top: 1rem;
  box-sizing: border-box;
  /*font: inherit;*/
  /*border-radius: 0.2rem;*/
  /*border: 2px solid #d4d5d6;*/
  color: #565656;
  -webkit-appearance: none;
}

.emailInput:focus {
  border-color: cornflowerblue;
  outline: none;
}

.emailInput.has-error {
  border-color: tomato;
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tag-item {
  background-color: #d4d5d6;
  display: inline-block;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;
}

.tag-item > .button {
  background-color: white;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font: inherit;
  margin-left: 10px;
  font-weight: bold;
  padding: 0;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* END: EmailEntry.jsx styles  */
.highlight-card {
  animation: highlight 1s;
}

@keyframes highlight {
  from {
    background-color: rgb(225 92 84 / var(--tw-bg-opacity));
  }
}

.react-multi-email > [type="text"]:focus {
  --tw-ring-shadow: 0 !important;
}

.react-multi-email > [type="text"] {
  font-size: 0.85rem;
}

.borderless:focus {
  background: transparent;
  border: 0;
  border-style: none;
  border-color: transparent;
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}

.borderless {
  background: transparent;
  border: 0;
  border-style: none;
  border-color: transparent;
  outline: none;
  outline-offset: 0;
  box-shadow: none;
}

.topic-bar-tooltip {
  opacity: 1 !important;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  color: #4b5563 !important;
  padding-left: 7px !important;
  padding-right: 7px !important;
  pointer-events: auto !important;
}

.listWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 0px 4px 25px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.outerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2px 0px 2px 0;
  gap: 15px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.innerWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 2px;
  flex: none;
  order: 1;
  flex-grow: 0;
}

.topicText {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  padding: 5px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  color: #4b5563;
  order: 2;
  flex-grow: 0;
  cursor: pointer;
}
.topicText:hover {
  background: #eeeded;
}
.topicText:active {
  background: #e0e0e0;
}

.playTopic {
  display: none;
  color: rgb(77 179 179 / var(--tw-bg-opacity));
  fill: rgb(77 179 179 / var(--tw-bg-opacity));
}

.topicText:hover .playTopic {
  display: block;
}

.toggleButton {
  cursor: pointer;
  width: 18px;
  height: 18px;
}

.toggleWrapper {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
}
.toggleWrapper:hover {
  background: #eeeded;
  border-radius: 4px;
}
.toggleWrapper:active {
  background: #e0e0e0;
}

.expandAll {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;

  /* tw-gray-500 */

  color: #6b7280;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  cursor: pointer;
  border-radius: 4px;
  padding: 5px;
}
.expandAll:hover {
  background: #eeeded;
}
.expandAll:active {
  background: #e0e0e0;
}

.noteItem {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2px 0px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.actionItemsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.actionItemOuter {
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 10px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
}

.actionItemInner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 2px;
  margin-left: 15px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}

.actionItemText {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: left;

  /* tw-gray-600 */

  color: #4b5563;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.actionItemOuter:hover {
  background: #eeeded;
}
.actionItemOuter:active {
  background: #e0e0e0;
}

.playActionItem {
  display: none;
  color: rgb(77 179 179 / var(--tw-bg-opacity));
  fill: rgb(77 179 179 / var(--tw-bg-opacity));
}

.actionItemOuter:hover .playActionItem {
  display: block;
}

.actionItemDetails {
  /* Frame 100 */

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.actionItemDetailsText {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* tw-gray-500 */

  color: #6b7280;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

/*Meeting Screen Styles*/
.txitem-parent-block:hover .txitem-metadata-block {
  border-right-color: #64bdbd;
}

.google-signin-btn {
  color: #444;
  background-color: #fff;
  border-color: #d6d6d6;
  border-radius: 2px;
  height: 40px;
  padding: 10px 20px;
  line-height: 18px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.google-signin-btn:hover,
.google-signin-btn:focus {
  background-color: #eee;
}

.google-signin-icon {
  margin-right: 10px;
}

.markdown-style p {
  line-height: 1.6; /* Adjust for better readability */
}

.markdown-style strong {
  font-weight: bold;
  font-size: medium; /* or a specific size */
}

/* MarkdownStyles.css */
.markdown-style h1 {
  font-size: 20px; /* Example size, adjust as needed */
  font-weight: bold;
  margin-bottom: 0.5em;
}

.markdown-style h2 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0.5em;
  margin-top: 1em;
  color: #333; /* Example color, adjust as needed */
}

.markdown-style h3 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0.5em;
  margin-top: 1em;
  color: #333; /* Example color, adjust as needed */
}

.markdown-style ol,
.markdown-style ul {
  padding-left: 20px; /* Adjust the indentation of lists */
}

.markdown-style li {
  margin-bottom: 10px; /* Keep as is or adjust as needed */
}

.markdown-style li > p {
  margin-top: 0; /* Removes top margin pushing content down */
  margin-bottom: 0; /* Adjust as needed, reduces space below */
  /* Optional: if you find the text still not aligned perfectly, try adjusting padding */
}

/* Ensure list-style-type is applied correctly for clarity */
.markdown-style ol {
  list-style-type: decimal; /* No change here, just for context */
}

.markdown-style ul {
  list-style-type: disc; /* No change here, just for context */
}

.meetings-list {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

/* For Webkit (Chrome, Safari, etc.) */
.meetings-list::-webkit-scrollbar {
  width: 8px;
}

.meetings-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.meetings-list::-webkit-scrollbar-thumb {
  background: #888;
}

.meetings-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* adjust the margin value based on your gap */
}

.my-masonry-grid_column {
  padding-left: 30px; /* space between columns */
  background-clip: padding-box;
}

/* For two columns, ensure each column takes up half of the container's width */
@media (min-width: 768px) {
  /* Adjust this breakpoint as needed */
  .my-masonry-grid_column {
    width: 50%;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.checkmark-animation {
  animation: scaleIn 0.5s ease-in-out forwards;
}

.loading-state-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust based on your layout */
}

.globe-loader {
  width: 150px;
  height: 150px;
  animation: pulse 2s infinite ease-in-out;
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

.line-animation {
  animation: lightup 3s infinite ease-in-out;
}

@keyframes lightup {
  0%,
  100% {
    stroke: #bbb;
  }
  50% {
    stroke: #fff;
  }
}

.point-animation {
  animation: move 2s infinite ease-in-out;
}

@keyframes move {
  0% {
    r: 3;
  }
  50% {
    r: 5; /* Increase the radius */
    fill: #ff0; /* Change color */
  }
  100% {
    r: 3;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-5px) scale(1.03);
  }
}

.bounce {
  animation: bounce 1s ease-in-out infinite;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(156, 163, 175, 0.5) transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(156, 163, 175, 0.5);
  border-radius: 3px;
}
